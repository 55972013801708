const routes = [
  {
    index: true,
    label: "MagiTech",
    path: "/",
  },
  {
    label: "Projects",
    path: "/projects",
  },
  {
    label: "Contact",
    path: "/contact",
  },
  {
    label: "About Me",
    path: "https://itsDigvijaysing.github.io",
  },
];

export default routes;
